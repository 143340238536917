<template>
  <div class="hmpg-slider">
    <carousel
      :mouseDrag="true"
      :touchDrag="true"
      :items-to-show="1"
      :wrapAround="true"
      :pauseAutoplayOnHover="true"
    >
      <slide style="width: 100%" v-for="slide in sliderData" :key="slide">
        <div
          class="slide"
          :style="{ 'background-image': `url(${slide.image})` }"
        >
          <div
            class="govuk-width-container"
            style="text-align: center; height: 100%"
          >
            <div class="govuk-main-wrapper" style="height: 100%">
              <div class="slideContentWrapper">
                <div class="slideContent">
                  <h2 style="padding: 0 50px" class="govuk-heading-m">
                    {{ slide.caption }}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </slide>

      <template #addons>
        <navigation />
      </template>
    </carousel>
  </div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";
export default {
  components: {
    Carousel,
    Slide,

    Navigation,
  },
  name: "HomePageSlider",
  data() {
    return {
      sliderData: [
        {
          image: require("@/assets/Tatry.png"),
          caption:
            "Národný koordinátor pre zvyšovanie bezpečnosti cestnej premávky",
        },
        {
          image: require("@/assets/becepacik.png"),
          caption:
            "Pripravujeme pre Vás zábavnú edukatívnu hru pre deti z oblasti bezpečnosti cestnej premávky",
        },
        {
          image: require("@/assets/viziaNulaBanner.png"),
          caption:
            "Iniciatíva znížiť počet usmrtených a ťažko zranených osôb pri dopravných nehodách na 0 do roku 2050",
        },
      ],
      //   openBanner: false,
    };
  },
};
</script>

<style lang="scss">
.hmpg-slider .slideContentWrapper {
  height: 100%;
  display: flex;
  align-items: center;
}
.hmpg-slider .carousel__next:hover {
  background-color: #ffdf0f;
}
.hmpg-slider .carousel__prev:hover {
  background-color: #ffdf0f;
}
.hmpg-slider .carousel__next {
  right: 20px !important;
  background-color: #dee0e2;
}
.hmpg-slider .carousel__prev {
  left: 20px !important;
  background-color: #dee0e2;
}
.hmpg-slider .carousel__icon {
  fill: black;
}
@media screen and (max-width: 960px) {
  .hmpg-slider .slide {
    // left: 0 !important;
    height: 250px;
    // position: relative;
    width: 100%;

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .hmpg-slider .slideContent > h2 {
    font-size: 1.5rem;
  }
  .hmpg-slider .slideContent {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 400px;
    width: 90%;
    margin: 0 auto;
    background-color: #ffffffd5;
    z-index: 20;
    height: 175px;
  }
}
@media screen and (min-width: 960px) {
  .hmpg-slider .slide {
    height: 500px;
    position: relative;
    width: 100%;

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .hmpg-slider .slideContent > h2 {
    font-size: 32px;
  }
  .hmpg-slider .slideContent {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 500px;
    height: 300px;
    background-color: #ffffffd5;
    z-index: 20;
  }
}
</style>
