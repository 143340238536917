<template>
  <div class="govuk-width-container">
    <!-- <h1 class="govuk-heading-l">Prístupnosť webových sídel</h1>
    <h2 class="govuk-heading-m black">
      „Zdravý človek má tisíc želaní, chorý iba jedno.“
    </h2>
    <p>Podľa štatistík SR je:</p>
    <ul>
      <li><p>
        <p>
          337 147 obyvateľov Slovenska oficiálne vedených ako zdravotne
          postihnutých,
        </p>
      </p></li>
      <li><p>
        <p>viac ako 932 000 obyvateľov má nad 65 rokov.</p>
      </p></li>
    </ul>

    <h2 class="govuk-heading-m black">O čo ide?</h2>
    <p>
      Slovensko pracuje na optimalizovaní webových stránok verejnej správy tak,
      aby rešpektovali používateľa, jeho zdravotnú spôsobilosť, vedomosti,
      skúsenosti, ale aj technické vybavenie.
    </p>
    <h2 class="govuk-heading-m black">Čo je náš cieľ?</h2>
    <p>
      Naším cieľom je, aby webové stránky (nielen) verejnej správy pri používaní
      nekládli zásadné, resp. žiadne prekážky, aby webové sídla mohli z pohľadu
      obsahu, služieb a funkcií používať rovnako zdravé osoby, ako aj akokoľvek
      znevýhodnené osoby bez ohľadu na vek.
    </p>

    <h2 class="govuk-heading-m black">Opora v zákone</h2>
    <p>
      Zákon č. 95/2019 Z. z. o informačných technológiách vo verejnej správe a o
      zmene a doplnení niektorých zákonov. <br />Vyhláška ÚPVII č. 78/2020 Z. z.
      o štandardoch pre ITVS v znení vyhlášky MIRRI č. 546/2020 Z. z..
    </p>
    <h2 class="govuk-heading-m black">Pre koho to robíme?</h2>
    <h2 class="govuk-heading-l black">Spoločnosť</h2>
    <p>
      Zdravotne hendikepovaní sa budú vďaka prístupnejším informáciám v online
      svete môcť rýchlejšie začleniť do spoločnosti a stať sa ekonomicky
      aktívnym obyvateľstvom.
    </p>
    <h2 class="govuk-heading-l black">Zdravotne znevýhodnení a seniori</h2>
    <p>
      Nielen nevidiaci a slabozrakí, sluchovo, motoricky, či inak hendikepovaní
      ľudia, ale aj seniori majú právo získavať informácie z online zdrojov.
    </p>
    <h2 class="govuk-heading-l black">Technologické nástroje</h2>
    <p>
      Dodržiavanie navrhnutých pravidiel vytvára cenné prostredie napr. aj pre
      automatické nástroje, ktoré nám tak poskytujú v rámci vyhľadávania
      relevantnejšie výsledky a všetkým nám tým šetria čas.
    </p>
    <h2 class="govuk-heading-m black">Ak sa niečo nepodarí na prvý krát?</h2>
    <p>
      Ak používateľ zistí nesúlad s webovou prístupnosťou ktoréhokoľvek webového
      sídla verejnej správy má možnosť kontaktovať priamo správcu webového
      sídla. V prípade, že správca webového sídla nereaguje na podnet,
      používateľ sa má možnosť obrátiť na Ministerstvo investícií, regionálneho
      rozvoja a informatizácie Slovenskej republiky.<br /><br /><span
        style="font-weight: 700"
        >Každý</span
      >
      má právo na prístup k online informáciám.<br /><br />Leták k webovej
      prístupnosti si môžete aj stiahnuť:
      <a
        :href="require('@/assets/Začleňme-sa-digitálne-V4.pdf')"
        target="_blank"
        title="Nasledujúci odkaz otvorí súbpr Začleňme sa digitálne v novom okne."
        class="govuk-link-custom"
        >Začleňme sa digitálne (PDF, 183 kb)</a
      >
    </p> -->
    <div>
      <div>
        <h1 class="govuk-heading-l">Vyhlásenie o prístupnosti</h1>
      </div>

      <div>
        <div>
          <p>
            Ministerstvo dopravy Slovenskej republiky má záujem zabezpečiť
            prístupnosť svojho webového sídla v&nbsp;súlade so zákonom č.
            95/2019 Z. z. o informačných technológiách vo verejnej správe
            a&nbsp;príslušnými vykonávacími predpismi v rozsahu podmienok podľa
            smernice (EÚ) 2016/2102.
          </p>
          <p>
            Toto vyhlásenie o&nbsp;prístupnosti sa vzťahuje na webové
            sídlo&nbsp;<a href="https://becep.sk">www.becep.sk</a>.
          </p>
          <h3 class="govuk-heading-m black"><strong>Stav súladu</strong></h3>
          <p>
            Toto webové sídlo je v čiastočnom súlade&nbsp;so zákonom č. 95/2019
            Z. z. o informačných technológiách vo verejnej správe
            a&nbsp;o&nbsp;zmene a&nbsp;doplnení niektorých zákonov v&nbsp;znení
            neskorších predpisov a príslušnými vykonávacími predpismi v rozsahu
            podmienok podľa smernice (EÚ) 2016/2102 vzhľadom na prvky nesúladu
            uvedené nižšie.
          </p>
          <h3 class="govuk-heading-m black">
            <strong>Neprístupný obsah</strong>
          </h3>
          <p>Obsah uvedený nižšie nie je prístupný z týchto dôvodov:</p>
          <ol>
            <li>
              <p>
                Nesúlad so smernicou (EÚ) 2016/2102 o prístupnosti webových
                sídel a mobilných aplikácií subjektov verejného sektora:
              </p>
            </li>
          </ol>
          <ul>
            <li>
              <p>
                Obrázky v&nbsp;PDF dokumentoch neobsahujú alternatívny text.
                [Kritérium úspešnosti 1.1.1 Netextový obsah]
              </p>
            </li>

            <li>
              <p>
                Pre niektoré hypertextové odkazy sa farba používa ako jediný
                rozlišovací prostriedok od bežného textu. [Kritérium úspešnosti
                1.4.1 Použitie farieb]
              </p>
            </li>
            <li>
              <p>
                Zameranie niektorých navigačných prvkov nemá dostatočný
                kontrast. [Kritérium úspešnosti 1.4.3 Kontrast (minimálny)]
              </p>
            </li>

            <li>
              <p>
                Niektoré netextové prvky nemajú dostatočný kontrast. [Kritérium
                úspešnosti 1.4.11 Kontrast netextových prvkov]
              </p>
            </li>
            <li>
              <p>
                Na podstránkach nefunguje mechanizmus na preskočenie opakujúcich
                sa častí obsahu. [Kritérium úspešnosti 2.4.1&nbsp; Preskočenie
                blokov]
              </p>
            </li>
            <li>
              <p>
                Niektoré odkazy nie sú dostatočne zrozumiteľné mimo kontext.
                [Kritérium úspešnosti 2.4.4 Účel odkazu (v kontexte)]
              </p>
            </li>

            <li>
              <p>
                Na stránke sa vyskytujú nesprávne vnorenia prvkov. [Kritérium
                úspešnosti 4.1.1 Syntaktická analýza]
              </p>
            </li>
          </ul>
          <ol start="2">
            <li>
              <p>
                Nesúlad so slovenskými všeobecne záväznými právnymi predpismi,
                upravujúcimi štandardy týkajúce sa prístupnosti webových sídel a
                mobilných aplikácii:
              </p>
            </li>
          </ol>
          <ul>
            <li>
              <p>
                Na stránkach, ktoré zverejňujú povinne zverejňované informácie
                podľa osobitných predpisov, nie je uvedený dátum vytvorenia
                stránky a&nbsp;dátum poslednej aktualizácie. [§15 pís. k)
                vyhlášky UPVII č. 78/2020 o štandardoch pre informačné
                technológie verejnej správy]
              </p>
            </li>
            <li>
              <p>
                Niektoré odkazy nemajú uvedenú významovo výstižnú informáciu ako
                text odkazu. [§16 pís. f) vyhlášky UPVII č. 78/2020 o
                štandardoch pre informačné technológie verejnej správy]
              </p>
            </li>
            <li>
              <p>
                Niektoré odkazy nie sú doplnené o informáciu o&nbsp;formáte a
                veľkosti súboru. Používateľ nie je informovaný, že hypertextový
                odkaz vedie na súbor iného formátu (napr. RTF, DOCX, XLS, PDF,
                ZIP atď.). [§18 pís. i) vyhlášky UPVII č. 78/2020 o štandardoch
                pre informačné technológie verejnej správy]
              </p>
            </li>
          </ul>
          <h3 class="govuk-heading-m black">
            <strong>Vypracovanie tohto vyhlásenia o&nbsp;prístupnosti</strong>
          </h3>
          <p>Toto vyhlásenie bolo vypracované 20.06.2022.</p>
          <p>
            Vyhodnotenie súladu webového sídla s&nbsp;požiadavkami zákona č.
            95/2019 Z. z. o informačných technológiách vo verejnej správe a
            príslušnými vykonávacími predpismi v rozsahu podmienok podľa
            smernice (EÚ) 2016/2102 bolo vykonané samohodnotením.
          </p>
          <p>Vyhlásenie bolo naposledy skontrolované 20.06.2022.</p>
          <h3 class="govuk-heading-m black">
            <strong>Spätná väzba a&nbsp;kontaktné informácie</strong>
          </h3>
          <p>
            V prípade akýchkoľvek problémov s prístupnosťou webového sídla nás,
            prosím, kontaktujte na e-mailovej adrese:&nbsp;<a
              href="mailto:becep@mindop.sk"
              >becep@mindop.sk</a
            ><u>.</u>&nbsp;Správcom webového sídla je Ministerstvo dopravy
            Slovenskej republiky a&nbsp;jeho prevádzkovateľom je Ministerstvo
            dopravy Slovenskej republiky.
          </p>

          <h3 class="govuk-heading-m black">
            <strong>Vynucovacie konanie</strong>
          </h3>
          <p>
            V&nbsp;prípade neuspokojivých odpovedí na podnety alebo žiadosti
            zaslané v&nbsp;rámci spätnej väzby subjektu verejného sektora
            v&nbsp;súlade s&nbsp;<a
              href="https://eur-lex.europa.eu/legal-content/SK/TXT/?uri=uriserv:OJ.L_.2016.327.01.0001.01.SLK&amp;toc=OJ:L:2016:327:TOC"
              >čl. 7 ods. 1 písm. b) smernice Európskeho parlamentu
              2016/2102&nbsp;</a
            >sa môžete obrátiť v&nbsp;rámci vynucovacieho konania na subjekt
            poverený presadzovaním Smernice, ktorým je Ministerstvo investícií,
            regionálneho rozvoja a informatizácie Slovenskej republiky
            a&nbsp;informatizáciu na e-mailovej adrese:&nbsp;<a
              href="mailto:standard@mirri.gov.sk"
              >standard@mirri.gov.sk</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useMeta } from "vue-meta";

export default {
  name: "AccessibilityView",
  setup() {
    useMeta({
      title: "Vyhlásenie o prístuponsti",
      htmlAttrs: { lang: "sk", amp: true },
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content:
            "Slovensko pracuje na optimalizovaní webových stránok verejnej správy tak, aby rešpektovali používateľa, jeho zdravotnú spôsobilosť, vedomosti, skúsenosti, ale aj technické vybavenie.",
        },
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1",
        },
      ],
    });
  },
};
</script>

<style scoped>
ul {
  list-style: unset;
}
ol {
  list-style: decimal;
}
</style>
