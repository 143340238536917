<template>
  <div data-module="idsk-footer-extended">
    <footer class="idsk-footer-extended" role="contentinfo">
      <div class="idsk-footer-extended-main-content">
        <div class="govuk-width-container">
          <div class="govuk-grid-column-full">
            <div class="idsk-footer-extended-description-panel">
              <div class="custom">
                <div class="idsk-footer-extended-meta-item">
                  <ul class="idsk-footer-extended-inline-list">
                    <li class="idsk-footer-extended-inline-list-item">
                      <router-link
                        class="govuk-link-custom"
                        title="Odkaz na podstránku o súbroch cookies."
                        to="/cookies"
                      >
                        Cookies
                      </router-link>
                    </li>
                    <li class="idsk-footer-extended-inline-list-item">
                      <router-link
                        class="govuk-link-custom"
                        title="Odkaz na podstránku s kontaktami"
                        to="/kontakty"
                      >
                        Kontakty
                      </router-link>
                    </li>
                    <li class="idsk-footer-extended-inline-list-item">
                      <router-link
                        class="govuk-link-custom"
                        title="Odkaz na podstránku o vyhlásení prístupnosti."
                        to="/vyhlasenie-o-pristupnosti"
                      >
                        Vyhlásenie o prístupnosti
                      </router-link>
                    </li>
                  </ul>
                </div>
                <div>
                  <p class="idsk-footer-extended-frame">
                    Vytvorené v súlade s
                    <a
                      class="govuk-link-custom"
                      title="Odkaz na oficálnu stránku Jednotného dizajn manuálu elektronických služieb, ktorá sa otvorí v novom okne."
                      href="https://idsk.gov.sk"
                      target="_blank"
                    >
                      Jednotným dizajn manuálom elektronických služieb.
                    </a>
                  </p>
                  <p class="idsk-footer-extended-frame">
                    Prevádzkovateľom služby je Ministerstvo dopravy SR.
                  </p>
                </div>
              </div>
              <div
                class="govuk-grid-column-one-third idsk-footer-extended-logo-box"
              >
                <a
                  href="https://www.mindop.sk/"
                  title="Po kliknutí sa v novom okne otvorí oficiálna stránka Ministerstva dopravy Slovenskej republiky."
                  target="_blank"
                >
                  <img
                    class="idsk-footer-extended-logo"
                    src="@/assets/ministerstvo_dopravy.svg"
                    alt="Ministerstvo dopravy Slovenskej republiky"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "FooterComponent",
  data() {
    return {
      openBanner: false,
    };
  },
};
</script>

<style scoped>
.idsk-footer-extended-description-panel {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.custom {
  width: 60%;
  padding-bottom: 30px;
}
@media (max-width: 645px) {
  .custom {
    width: 100%;
    padding-bottom: 30px;
  }
}
</style>
